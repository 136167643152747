import styled, { themeGet } from "@styled";

export const TrabajadorWrap = styled.div`
  background-color: ${(props) => props.fondo};
`;

export const DataItem = styled.div`
  background-color: ${(props) => props.primero};
  margin: 6px 0;
  padding: 16px 12px;
  text-align: center;
  i {
    margin-right: 10px;
  }
  * {
    color: ${themeGet("colors.black")};
  }
  color: ${themeGet("colors.black")};
`;
