import React from "react";
import { graphql } from "gatsby";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import Heading from "@ui/heading";
import Anchor from "@ui/anchor";
import { Container, Row, Col } from "@ui/wrapper";

import { TrabajadorWrap, DataItem } from "./style";

const TrabajadorTemplate = ({ data, pageContext, location }) => {
  const {
    cargo,
    ciudad,
    email,
    nombre,
    apellido,
    telefono,
    whatsapp,
    empresa,
  } = data.prismicTrabajador.data;

  const numero = whatsapp.text.replace(/\D/g, "");

  const whatsappLink = `https://wa.me/51${numero}?text=Hola%20${encodeURIComponent(
    nombre.text
  )}`;
  const fondo = empresa.document.data.color_fondo;
  const logo = empresa.document.data.logo_claro.gatsbyImageData;
  const primero = empresa.document.data.primer_plano;

  return (
    <Layout location={location} fondo={fondo}>
      <Header fondo={fondo} logo={logo} />
      <TrabajadorWrap
        fondo={fondo}
        primerPlano={empresa.document.data.primer_plano}
      >
        <Container>
          <Row>
            <Col>
              <DataItem primero={primero}>
                <Heading as="h3">{nombre.text}</Heading>
                <Heading as="h3">{apellido.text}</Heading>
                <Heading as="h5">{cargo.text}</Heading>
              </DataItem>
              <DataItem primero={primero}>
                <Anchor href={`tel:${telefono.text}`}>
                  <i className="fas fa-phone" />
                  Tel: {telefono.text} <b>{ciudad.text}</b>
                </Anchor>
              </DataItem>
              <DataItem primero={primero}>
                <Anchor href={whatsappLink}>
                  <i className="fas fa-mobile-alt" />
                  Whatsapp: {whatsapp.text}
                </Anchor>
              </DataItem>
              <DataItem primero={primero}>
                <Anchor href={`mailto:${email.text}`}>
                  <i className="fas fa-envelope" />
                  {email.text}
                </Anchor>
              </DataItem>
              <DataItem primero={primero}>
                <Anchor href={empresa.document.data.web.url}>
                  <i className="fas fa-globe" />
                  {empresa.document.data.web.url}
                </Anchor>
              </DataItem>
            </Col>
          </Row>
        </Container>
      </TrabajadorWrap>
      <Footer fondo={fondo} />
    </Layout>
  );
};

export const query = graphql`
  query ($uid: String) {
    prismicTrabajador(uid: { eq: $uid }) {
      data {
        cargo {
          text
        }
        ciudad {
          text
        }
        email {
          text
        }
        empresa {
          document {
            ... on PrismicEmpresa {
              data {
                color_fondo
                primer_plano
                segundo_plano
                logo_claro {
                  gatsbyImageData
                }
                web {
                  url
                }
              }
            }
          }
        }
        nombre {
          text
        }
        apellido {
          text
        }
        telefono {
          text
        }
        whatsapp {
          text
        }
      }
    }
  }
`;

export default TrabajadorTemplate;
